export default {
    namespaced: true,

    state: () => ({
        warehouses: [],
        warehousesAvailableForPicker: [],
        warehousePrinters: [],
    }),
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        setWarehouses(state, warehouses) {
            state.warehouses = warehouses;
        },
    },
    actions: {
        getWarehouses(store) {
            return new Promise((resolve => {
                WV.Api.getWarehouses()
                    .then(response => {
                        store.commit('setWarehouses', response.data);
                        resolve();
                    });
            }));
        },
        getWarehousesAvailableForPicker(store) {
            return new Promise((resolve => {
                WV.Api.getWarehousesAvailableForPicker()
                    .then(response => {
                        store.state.warehousesAvailableForPicker = response.data;
                        resolve();
                    });
            }));
        },
        getWarehousePrinters(store, warehouseId) {
            return new Promise((resolve => {
                WV.Api.getWarehousePrinters(warehouseId)
                    .then(response => {
                        store.commit('set', ['warehousePrinters', response.data]);
                        resolve();
                    });
            }));
        },
    },
    getters: {
    }
};
