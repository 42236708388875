export default {
    namespaced: true,

    state: () => ({
        order: false,
    }),
    mutations: {
        setOrder(state, order) {
            state.order = order;
        },
        resetState(state) {
            state.order = false;
        },
        setDemoOrder(state) {
            state.order = {
                order_id: "3456789",
                created_at: "2024-01-10T14:15:22Z",
                products: [
                    {
                        gtin: "340123456789",
                        product_name: "Acetylsalicylic acid",
                        quantity: 3
                    },
                    {
                        gtin: "345634012789",
                        product_name: "Test product with really long name that should be presented correctly too",
                        quantity: 2
                    },
                ]
            };
        },
    },
    actions: {
        getOrder(store, orderId) {
            return new Promise(((resolve, reject) => {
                WV.Api.getOrderForPicking(orderId)
                    .then(response => {
                        store.commit('setOrder', response.data);
                        resolve();
                    }).catch(() => {
                        reject();
                    });
            }));
        },
        markOrderAsPicked(store, orderId) {
            return new Promise((resolve => {
                WV.Api.markOrderAsPicked(orderId)
                    .then(() => {
                        resolve();
                    });
            }));
        },
        markOrderAsRejected(store, orderId) {
            return new Promise((resolve => {
                WV.Api.markOrderAsRejected(orderId)
                    .then(() => {
                        resolve();
                    });
            }));
        },
    },
    getters: {

    }
};
