export default {
    namespaced: true,

    state: () => ({
        //profile: false,
        profile: {
            user_id: "4",
            first_name: "George",
            last_name: "Orwell",
            current_warehouse_id: 183,
            warehouse_printer_id: false
        },
    }),
    mutations: {
        setProfile(state, profile) {
            state.profile = profile;
        },
    },
    actions: {
        getProfile(store) {
            return new Promise((resolve => {
                WV.Api.getProfile()
                    .then(response => {
                        store.commit('setProfile', response.data);
                        resolve();
                    });
            }));
        },
        updateProfile(store) {
            return new Promise((resolve => {
                WV.Api.updateProfile(store.state.profile)
                    .then(response => {
                        store.commit('setProfile', response.data);
                        resolve();
                    });
            }));
        },
    },
    getters: {

    }
};
