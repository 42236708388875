export default [
    {
        en: 'Profile',
        es: 'Perfil',
        it: 'Profilo',
        ru: 'Профиль',
    },
    {
        en: 'Warehouse',
        es: 'Almacén',
        it: 'Magazzino',
        ru: 'Склад',
    },
    {
        en: 'Current warehouse',
        es: 'Almacén actual',
        it: 'Magazzino attuale',
        ru: 'Склад работы',
    },
    {
        en: 'Select warehouse',
        es: 'Selecciona almacén',
        it: 'Seleziona magazzino',
        ru: 'Выберите текущий склад',
    },
    {
        en: 'Label printer',
        es: 'Impresora de etiquetas',
        it: 'Stampante per etichette',
        ru: 'Принтер наклеек',
    },
    {
        en: 'Select label printer',
        es: 'Selecciona impresora de etiquetas',
        it: 'Seleziona stampante per etichette',
        ru: 'Выберите принтер наклеек',
    },
    {
        en: 'User settings',
        es: 'Configuración de usuario',
        it: 'Impostazioni utente',
        ru: 'Пользовательские настройки',
    },
    {
        en: 'First name',
        es: 'Nombre',
        it: 'Nome',
        ru: 'Имя',
    },
    {
        en: 'Enter your first name',
        es: 'Introduce tu nombre',
        it: 'Inserisci il tuo nome',
        ru: 'Введите ваше имя',
    },
    {
        en: 'Last name',
        es: 'Apellido',
        it: 'Cognome',
        ru: 'Фамилия',
    },
    {
        en: 'Enter your last name',
        es: 'Introduce tu apellido',
        it: 'Inserisci il tuo cognome',
        ru: 'Введите вашу фамилию',
    },
    {
        en: 'Save changes',
        es: 'Guardar cambios',
        it: 'Salva modifiche',
        ru: 'Сохранить изменения',
    },
];

