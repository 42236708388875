import { createStore } from 'vuex';
import menu from './menu.js';
import auth from './moduleAuth.js';
import profile from './moduleProfile.js';
import preorders from './modulePreorders.js';
import products from './moduleProducts.js';
import warehouses from './moduleWarehouses.js';
import picking from './modulePicking.js';

export default createStore({
    modules: {
        auth,
        profile,
        preorders,
        products,
        warehouses,
        picking,
    },
    state: {
        menu: menu,
    },
    mutations: {
        set(state, payload) {
            state[payload[0]] = payload[1];
        },
        clean(state, items) {
            Object.keys(items).forEach(key => {
                state[key] = items[key];
            });
        },
    },
    actions: {},
    getters: {},
});
