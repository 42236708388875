import {createApp} from 'vue';

import common from './translations/common';
import home from './translations/home';
import profile from './translations/profile';
import picking from './translations/picking';
import preorders from './translations/preorders';
import acceptance from './translations/acceptance';

const Lng = {
    name: 'Lng',
    data() {
        return {
            currentLanguageCode: 'en',
            modules: {
                common,
                profile,
                home,
                picking,
                preorders,
                acceptance,
            },
            languages: [
                {
                    shortname: 'English',
                    name: 'English (UK)',
                    icon: 'geoEN',
                    iso: 'en',
                    id: 'en',
                },
                {
                    shortname: 'Русский',
                    name: 'Русский (RU)',
                    icon: 'geoRU',
                    iso: 'ru',
                    id: 'ru',
                },
                {
                    shortname: 'Spain',
                    name: 'Español (ES)',
                    icon: 'geoES',
                    iso: 'es',
                    id: 'es',
                },
                {
                    shortname: 'Italian',
                    name: 'Italiano (IT)',
                    icon: 'geoIT',
                    iso: 'it',
                    id: 'it',
                },
            ],
        };
    },
    computed: {},
    methods: {
        getTerm(namespace, string) {
            let term = string;
            //console.log('term: ', term);
            //console.log('module: ', this.modules[namespace]);
            if (typeof this.modules[namespace] === 'undefined') {
                console.log(`UNDEFINED TRANS: (${this.currentLanguageCode}) ${namespace} - "${term}"`);
            } else {
                let item = collect(this.modules[namespace])
                    .where('en', term)
                    .first();
                if (item) term = item[this.currentLanguageCode] || term;
                //else console.log(`TRANS: (${this.currentLanguageCode}) ${namespace} - "${term}"`);
            }
            return term;
        },

        __(namespace, string) {
            return this.getTerm(namespace, string);
        },

        isLngCodeValid(languageCode) {
            return ['ru', 'en', 'es', 'it'].indexOf(languageCode) !== -1;
        },

        setLanguage(languageCode) {
            console.log('setLanguage: ', languageCode);
            if (!this.isLngCodeValid(languageCode)) {
                console.error(`Language code "${languageCode}" is not valid`);
            } else {
                this.currentLanguageCode = languageCode;
                localStorage.setItem('lng', languageCode);
            }
        },
        setLanguageFromLocalStorage() {
            if (localStorage.getItem('lng') !== null && this.isLngCodeValid(localStorage.getItem('lng'))) {
                this.currentLanguageCode = localStorage.getItem('lng');
            }
        },

    },
    beforeMount() {
        this.setLanguageFromLocalStorage();
    },
    mounted() {},

};

export default createApp(Lng);
