export default [
    {
        en: 'Preorders',
        es: 'Pedidos anticipados',
        it: 'Preordini',
        ru: 'Предзаказы',
    },
    {
        en: 'Barcode',
        es: 'Código de barras',
        it: 'Codice a barre',
        ru: 'Штрихкод',
    },
    {
        en: 'Scan SSCC or GTIN code',
        es: 'Escanea el código SSCC o GTIN',
        it: 'Scansiona il codice SSCC o GTIN',
        ru: 'Отсканируйте SSCC или GTIN код',
    },
    {
        en: 'Invalid code format',
        es: 'Formato de código no válido',
        it: 'Formato del codice non valido',
        ru: 'Неверный формат кода',
    },
    {
        en: 'Supplier',
        es: 'Proveedor',
        it: 'Fornitore',
        ru: 'Поставщик',
    },
    {
        en: 'Select supplier',
        es: 'Selecciona proveedor',
        it: 'Seleziona fornitore',
        ru: 'Выберите поставщика',
    },
    {
        en: 'Product',
        es: 'Producto',
        it: 'Prodotto',
        ru: 'Товар',
    },
    {
        en: 'Select product',
        es: 'Selecciona producto',
        it: 'Seleziona prodotto',
        ru: 'Выберите товар',
    },
    {
        en: 'Reset filters',
        es: 'Restablecer filtros',
        it: 'Reimposta filtri',
        ru: 'Сбросить фильтры',
    },
    {
        en: 'There are no preorders to show.',
        es: 'No hay pedidos anticipados para mostrar.',
        it: 'Non ci sono preordini da mostrare.',
        ru: 'Нет предзаказов для отображения.',
    },
    {
        en: 'Try to',
        es: 'Intenta',
        it: 'Prova a',
        ru: 'Попробуйте',
    },
    {
        en: 'reset filters',
        es: 'restablecer los filtros',
        it: 'reimpostare i filtri',
        ru: 'сбросить фильтры',
    },
    {
        en: 'pcs.',
        es: 'uds.',
        it: 'pz.',
        ru: 'шт.',
    },
    {
        en: 'from supplier',
        es: 'del proveedor',
        it: 'dal fornitore',
        ru: 'от поставщика',
    },
];

