export default [
    {
        en: 'Scan invoice or enter order ID',
        es: 'Escanea la factura o ingresa el ID del pedido',
        it: 'Scansiona la fattura o inserisci l\'ID dell\'ordine',
        ru: 'Отсканируйте инвойс',
    },
    {
        en: 'Open order',
        es: 'Abrir pedido',
        it: 'Apri ordine',
        ru: 'Открыть заказ',
    },
    {
        en: 'Order picking',
        es: 'Preparación de pedidos',
        it: 'Preparazione degli ordini',
        ru: 'Сборка заказа',
    },
    {
        en: 'Order',
        es: 'Pedido',
        it: 'Ordine',
        ru: 'Заказ',
    },
    {
        en: 'Scan GTIN barcode',
        es: 'Escanea el código de barras GTIN',
        it: 'Scansiona il codice a barre GTIN',
        ru: 'Отсканируйте GTIN штрихкод',
    },
    {
        en: 'Invalid GTIN',
        es: 'GTIN no válido',
        it: 'GTIN non valido',
        ru: 'Неверный GTIN',
    },
    {
        en: 'Products in order',
        es: 'Productos en el pedido',
        it: 'Prodotti nell\'ordine',
        ru: 'Товары в заказе',
    },
    {
        en: 'Reject',
        es: 'Rechazar',
        it: 'Rifiuta',
        ru: 'Сбросить',
    },
    {
        en: 'Finalize',
        es: 'Finalizar',
        it: 'Finalizza',
        ru: 'Завершить',
    },
    {
        en: 'rejected',
        es: 'rechazado',
        it: 'rifiutato',
        ru: 'сброшен',
    },
    {
        en: 'of',
        es: 'de',
        it: 'di',
        ru: 'из',
    },
    {
        en: 'collected',
        es: 'recogidos',
        it: 'raccolti',
        ru: 'собраны',
    },
    {
        en: 'Product already collected. Check products quantity.',
        es: 'Producto ya recogido. Verifica la cantidad de productos.',
        it: 'Prodotto già raccolto. Controlla la quantità di prodotti.',
        ru: 'Товар уже собран. Проверьте количество товаров.',
    },
    {
        en: 'added',
        es: 'añadido',
        it: 'aggiunto',
        ru: 'добавлен',
    },
    {
        en: 'successfully finalized',
        es: 'finalizado con éxito',
        it: 'finalizzato con successo',
        ru: 'успешно собран',
    },
    {
        en: 'Wrong order ID',
        es: '',
        it: '',
        ru: 'Заказ с данным ID не найден',
    },
];
