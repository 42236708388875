export default [
    {
        en: 'Acceptance',
        es: 'Aceptación',
        it: 'Accettazione',
        ru: 'Приемка товара',
    },
    {
        en: 'Scan SSCC or GTIN barcode',
        es: 'Escanea el código de barras SSCC o GTIN',
        it: 'Scansiona il codice a barre SSCC o GTIN',
        ru: 'Отсканируйте штрихкод',
    },
    {
        en: 'Product',
        es: 'Producto',
        it: 'Prodotto',
        ru: 'Товар',
    },
    {
        en: 'Batch/Lot',
        es: 'Lote',
        it: 'Lotto',
        ru: 'Лот/Партия',
    },
    {
        en: 'Shipping warehouse',
        es: 'Almacén de envío',
        it: 'Magazzino di spedizione',
        ru: 'Склад отправки',
    },
    {
        en: 'Receiving warehouse',
        es: 'Almacén receptor',
        it: 'Magazzino di ricezione',
        ru: 'Склад приемки',
    },
    {
        en: 'Expiry date',
        es: 'Fecha de caducidad',
        it: 'Data di scadenza',
        ru: 'Срок годности',
    },
    {
        en: 'Date of arrival',
        es: 'Fecha de llegada',
        it: 'Data di arrivo',
        ru: 'Дата доставки',
    },
    {
        en: 'Ordered pcs.',
        es: 'Unidades pedidas',
        it: 'Pezzi ordinati',
        ru: 'Заказано шт.',
    },
    {
        en: 'Delivered pcs.',
        es: 'Unidades entregadas',
        it: 'Pezzi consegnati',
        ru: 'Доставлено шт.',
    },
    {
        en: 'Save',
        es: 'Guardar',
        it: 'Salva',
        ru: 'Сохранить',
    },
    {
        en: 'Cancel',
        es: 'Cancelar',
        it: 'Annulla',
        ru: 'Отменить',
    },
    {
        en: 'Enter batch/lot identifier',
        es: 'Ingrese el identificador del lote',
        it: 'Inserisci l\'identificatore del lotto',
        ru: 'Укажите идентификатор партии/лота',
    },
    {
        en: 'Fill expiry date',
        es: 'Complete la fecha de caducidad',
        it: 'Compila la data di scadenza',
        ru: 'Укажите дату истечения срока годности',
    },
    {
        en: 'Select receiving warehouse',
        es: 'Seleccione el almacén receptor',
        it: 'Seleziona il magazzino di ricezione',
        ru: 'Укажите склад приемки',
    },
    {
        en: 'Fill the number of delivered pieces',
        es: 'Complete el número de unidades entregadas',
        it: 'Compila il numero di pezzi consegnati',
        ru: 'Укажите количество доставленных единиц товара',
    },
    {
        en: 'The bar code format could not be recognized',
        es: 'El formato del código de barras no pudo ser reconocido',
        it: 'Il formato del codice a barre non è stato riconosciuto',
        ru: 'Не удалось распознать формат штрихкода',
    },
    {
        en: 'Supplier code in the SSCC does not match the supplier in the pre-order',
        es: 'El código del proveedor en el SSCC no coincide con el proveedor del pedido previo',
        it: 'Il codice fornitore nel SSCC non corrisponde al fornitore nel preordine',
        ru: 'Код поставщика в SSCC коде не совпадает с кодом поставщика в предзаказе',
    },
    {
        en: 'Acceptance has been successfully saved',
        es: 'La aceptación se ha guardado con éxito',
        it: 'L\'accettazione è stata salvata con successo',
        ru: 'Приемка партии успешно сохранена',
    },
];

