export default [
    {
        en: 'Order picking',
        es: 'Preparación de pedidos',
        it: 'Preparazione degli ordini',
        ru: 'Сборка заказов',
    },
    {
        en: 'Acceptance',
        es: 'Aceptación',
        it: 'Accettazione',
        ru: 'Приемка товара',
    },
    {
        en: 'Logout',
        es: 'Cerrar sesión',
        it: 'Finire il lavoro',
        ru: 'Завершить работу',
    },
];

